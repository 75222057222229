import { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import { KION_ERROR_PAGE_TYPE } from "../../../util/interfaces"
import { KionButton } from "../../button/button"
import s from './styles.module.scss'

interface ErrorScheme {
  title: string,
  msg?: string,
  btnName: string
  action: () => void
}

export function KionError() {
  const routerPush = useNavigate()

  const errorsMap: Map<KION_ERROR_PAGE_TYPE, ErrorScheme> = new Map([
    [KION_ERROR_PAGE_TYPE.LIMIT_REACHED,
      {
        title: 'Превышено число устройств',
        msg: `К учетной записи МТС ТВ можно подключить не более 5 устройств. Удалите устройство и попробуйте снова`,
        btnName: 'Перейти в настройки',
        action: () => routerPush('/')
      }
    ],
    [KION_ERROR_PAGE_TYPE.IMPOSSIBLE_TO_CONNECT,
      {
        title: 'Подключение невозможно',
        msg: `У Вас уже подключен пакет «Онлайн-кинотеатры». Для <br> подключения пакета «Развлекательное» необходимо отключить пакет <br> «Онлайн-кинотеатры». Совместное использование этих пакетов невозможно`,
        btnName: 'Перейти в настройки',
        action: () => routerPush('/')
      }
    ],
    [KION_ERROR_PAGE_TYPE.UNKNOWN_OPERATOR,
      {
        title: 'Неподдерживаемый оператор сотовой связи',
        msg: `Номер телефона принадлежит оператору сотовой связи, который не поддерживается в соответствии с условиями участия в акции. Введите другой номер телефона или приобретите SIM-карту оператора МТС`,
        btnName: 'Показать карту салонов МТС',
        action: () => window.location.href = 'https://moskva.mts.ru/personal/podderzhka/zoni-obsluzhivaniya/offices/'
      }
    ],
    [KION_ERROR_PAGE_TYPE.ACCOUNT_NOT_FOUND,
      {
        title: 'Телефон не зарегестрирован',
        msg: `Для начала необходимо зарегестрироваться в системе`,
        btnName: 'Зарегестрироваться',
        action: () => window.location.href = 'https://mtstv.mts.ru/home'
      }],
      [KION_ERROR_PAGE_TYPE.PROMO_OUTDATED, {
        title: `Промокод устарел`,
        btnName: 'Ввести другой промокод',
        action: () => routerPush('/')
      }]
  ])

  const [error, setError] = useState<ErrorScheme>({
    title: '',
    msg: `
        Неизестная ошибка. Что-то пошло не так. <br>
        Попробуйте вернуться позже или попробовать снова
    `,
    btnName: 'Назад',
    action: () => routerPush('/')
  })

  useEffect(() => {
    window.sessionStorage.removeItem("request_id")
    // const q = this.$route.query?.type
    // if (q && typeof q === 'string') {
    //   setError(errorsMap.get(q as KION_ERROR_PAGE_TYPE) || error)
    // }
  }, [])

  return (
    <div style={{
        background: '#090B3F',
        height: '100vh',
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
    }}>
        <div className={s['err-wrapper']}>
            <div className={s.icon}>
                <img src="/images/error-icon.svg"/>
            </div>
            { error.title }
            <span 
                className={s['err-msg']}
                dangerouslySetInnerHTML={{__html: error.msg!}} 
            />
            <KionButton onClick={error.action}>
            { error.btnName }
            </KionButton>
        </div>
    </div>
  )
}
